import React, { Component, useState, useEffect } from 'react';
import { useFormik, FormikProvider } from 'formik';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { sendData } from '../../pages/api/sendData';
import { string as yupString, object as yupObject } from 'yup';
import Icon from '../Icons/Icon';

const Formik = require('formik').Formik;
const Field = require('formik').Field;

// Dynamic imports
const InputField = dynamic(() => import('../InputField/index'));
const Button = dynamic(() => import('../Button/Button'));
const Arrow = dynamic(() => import('../../../public/assets/arrows/smallarrow'));
const CheckButton = dynamic(() => import('../CheckButton/index'));

const validation = yupObject().shape({
  first_name: yupString().required('Name is required'),
  email: yupString()
  .email()
  .trim()
  .required(),
});

const Popup = () => {
  const [submitted, setSubmit] = useState(null);
  const [opened, setOpened] = useState(false);
  const [cookies, setCookies] = useState([]);

  const s = require('./Popup.module.scss');

  const showPopUp = () => {
    setOpened(true)
  }

  const VISITED = 'vodacom:visited';

  useEffect(() => {
    const visited = localStorage.getItem(VISITED);
    if (!visited) {
      setTimeout(showPopUp, 1000);
      localStorage.setItem(VISITED, true);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      email: '',
      optin: false,
    },
    onSubmit: values => {
      setSubmit(true);
      sendData(values)
    },
  });


  return (
    <div>
      {opened && (
      <div className={s.Overlay}>
        <div className={s.form_wrap}>
          {!submitted ? (       
            <div className={s.form_wrapper}>
              {/* <div className={s.Shadow}> */}
                <a className={s.CloseOverlay} onClick={() => setOpened(false)}>
                  <Icon icon="ACTION_CLOSE" theme={'light_opacity_rounded'} size={30} />
                </a>
              {/* </div> */}
              <h1>Subscribe to our Newsletter:</h1>
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit} className={s.RegistrationForm}>
                  <div className={s.FormFrame}>
                    <div className={s.Field}>
                      <Field name="first_name" id="first_name" label="Name" borderBottom component={InputField} type="text" autoComplete="none" />
                    </div>
                    <div className={s.Field}>
                      <Field name="email" id="email" label="E-Mail" borderBottom component={InputField} type="text" autoComplete="none" />
                    </div>
                  </div>
                  <div className={s.IndentField}>
                    <Field
                      name="optin"
                      id="commsOptin"
                      label="Don't miss a thing.
                      Subscribe to our monthly newsletter for the latest in trending tech made simple"
                      component={CheckButton}
                      type="checkbox"
                    />
                  </div>

                  <div className={s.Well}>
                    <Button type="submit" theme="outline_white" ripple disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <span>Subscribing...</span> : <span>Subscribe<Arrow /></span>}
                    </Button>
                  </div>
                </form>
              </FormikProvider>
            </div>
            ) : (
            <div className={s.form_wrapperSuccess}>
              <div className={s.Shadow}>
                <a className={s.CloseOverlay} onClick={() => setOpened(false)}>
                  <Icon icon="ACTION_CLOSE" theme={'light_opacity_rounded'} size={30} />
                </a>
              </div>
              <h1 className={s.SuccessMsg}>Thank you for subscribing</h1>
            </div>
            )}
        </div>
      </div>
     )} 
    </div>
  );
}

Popup.propTypes = {
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  subscribed: PropTypes.func,
}

export default Popup;